<template>
    <div class="container">
        <div class="container_block">
            <div class="container_block_title"> {{title}}</div>
             <el-divider />
             <div class="container_block_content " v-html="content">
                 </div>
        </div>
    </div>
</template>
<script setup name="AnnounceDetail">
import {useRoute} from "vue-router";
import {getNewsDetail} from "@/api/news";
import {ref} from "vue";
const route = useRoute();
const id = route.query.id;
console.log(id);
const title = ref(null);
const content = ref(null);

const getDetail= async ()=>{
  let response=await getNewsDetail(id);
  console.error(response.data);
  title.value = response.data.noticeTitle;
  content.value = response.data.noticeContent;
/*  activity.value=response.data
  activity.value.starttime=activity.value.starttime.replace('-','年');
  activity.value.starttime=activity.value.starttime.replace('-','月')+'日';
  activity.value.endtime=activity.value.endtime.replace('-','年');
  activity.value.endtime=activity.value.endtime.replace('-','月')+'日';*/
}
getDetail();
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    width: 62%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_title{
        color: #333333;
        font-size: 20px;
        font-weight: bold;
    }
    &_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &_content{
        font-size: 16px;
        color: #424242;
    }

  }
}
</style>